export default function observeIntersection(
    el,
    callback,
    once,
    thresh = 0,
    root,
    rootm = '0px'
) {
    const options = {
        threshold: thresh,
        root,
        rootMargin: rootm,
    };
    const elContainer = document.getElementById(el);
    if (elContainer) {
        const elObserver = new IntersectionObserver((entries) => {
            entries.forEach((entry) => {
                if (entry.intersectionRatio > 0) {
                    callback('in');
                } else if (entry.intersectionRatio !== 1) {
                    callback('out');
                }
                if (once) {
                    elObserver.unobserve(entry.target);
                }
            });
        }, options);
        elObserver.observe(elContainer);
    }
}
